html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  background: rgb(244, 247, 252);
  color: #111;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  overflow: hidden;
}

input,
button {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1rem;
}

.-button {
  background-color: rgb(87, 114, 245);
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  padding: 18px 20px;
  text-decoration: none;
}

.center-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  padding: 20px;
  width: 100%;
}

.card-box {
  background-color: rgb(250, 253, 258);
  border-radius: 5px;
  box-shadow: rgba(60, 66, 87, 0.117647) 0px 7px 14px 0px, rgba(0, 0, 0, 0.117647) 0px 3px 6px 0px;
  max-width: 650px;
  width: 100%;
}

.card-box > .header {
  border-bottom: 1px solid #ddd;
  color: #444;
  padding: 30px;
}

.card-box > .header > .main {
  margin: 0;
  font-size: 1.5rem;
}

.card-box > .header > .sub {
  color: #555;
  margin-top: 10px;
}

.card-box > .content {
  padding: 40px;
}

.card-box > .content > .none {
  margin: 2px 0;
}

.card-box + .card-box {
  margin-top: 26px;
}

canvas {
  top: 0;
  left: 0;
}
